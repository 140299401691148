import useNav from "./useNav";
import TopNav from "./TopNav";
import FullScreenNav from "./FullScreenNav";

import "./style.scss";

export default function Nav() {
    const { navLinks, nextLang, changeLanguage, isShowFull, active } = useNav();
    const NavElement = isShowFull ? FullScreenNav : TopNav;

    return (
        <NavElement
            active={active}
            list={navLinks}
            nextLang={nextLang}
            changeLanguage={changeLanguage}
        />
    );
}
