import { useCallback, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import useResize from "../../../shared/hooks/useResize";
import i18n from "../../../shared/i18n";
const langList = [
    { name: "cn", label: "中文" },
    { name: "en", label: "EN" },
];
export default function useNav() {
    const { t } = useTranslation();
    const [nextLang, setNextLang] = useState({ name: i18n.language });
    const [isShowFull, setIsShowFull] = useState(false);
    const [active, setActive] = useState("/");

    const location = useLocation();
    useEffect(() => {
        setActive(location.pathname);
    }, [location.pathname]);

    const getNextLanguage = useCallback(() => {
        const curIndex = langList.findIndex((lang) => {
            return lang.name === i18n.language;
        });
        setNextLang(langList[curIndex + 1] || langList[0]);
    }, [setNextLang]);

    const changeLanguage = useCallback(() => {
        // 根据当前语言设置下一个语言的版本
        i18n.changeLanguage(nextLang.name);
        getNextLanguage();
        setTimeout(() => {
            window.location.reload();
        }, 0);
    }, [getNextLanguage, nextLang.name]);

    const onResize = useCallback(
        ({ ww }) => {
            setIsShowFull(ww < 750);
        },
        [setIsShowFull],
    );
    useResize(onResize, [onResize]);

    useEffect(() => {
        getNextLanguage();
        //eslint-disable-next-line
    }, []);

    const navLinks = [
        {
            label: t("nav.work"),
            link: "/",
        },
        {
            label: t("nav.about"),
            link: "/about",
        },
        {
            label: t("nav.contact"),
            link: "/contact",
        },
    ];
    return {
        navLinks,
        changeLanguage,
        nextLang,
        isShowFull,
        active,
    };
}
