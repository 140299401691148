import { useState } from "react";
import { Link } from "react-router-dom";

export default function FullScreenNav({
    list = [],
    nextLang = {},
    changeLanguage,
    active,
}) {
    const [isExtend, setExtend] = useState(false);
    return (
        <div className="fullscreen-nav z-10">
            <button
                className="fixed top-2 right-2 z-20"
                onClick={() => {
                    setExtend(!isExtend);
                }}
            >
                <span
                    className={`menu-icon ${isExtend ? "close-icon" : " "}`}
                ></span>
            </button>
            {isExtend && (
                <div className="fixed bg-white w-screen h-screen bg-white z-10 top-0 flex items-center">
                    <ul className="text-4xl w-full pl-20 font-bold flex-col items-center">
                        {list.map(({ label, link }) => {
                            return (
                                <li className="mt-10 first:mt-0">
                                    <Link
                                        onClick={() => {
                                            setExtend(false);
                                        }}
                                        to={link}
                                    >
                                        {label}
                                    </Link>
                                </li>
                            );
                        })}
                        {/* <li className="cursor-pointer mt-10">
                            <span
                                onClick={() => {
                                    changeLanguage();
                                    setExtend(false);
                                }}
                            >
                                {nextLang.label}
                            </span>
                        </li> */}
                    </ul>
                </div>
            )}
        </div>
    );
}
