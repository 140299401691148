import { Link } from "react-router-dom";

export default function TopNav({
    list = [],
    nextLang = {},
    changeLanguage,
    active,
}) {
    return (
        <div className="top-nav z-10 fixed top-0  w-full justify-end flex-row flex">
            <ul className="flex mr-5">
                {list.map(({ label = "", link = "", onClick = () => {} }) => {
                    const activeClass = link === active ? "active" : "";
                    return (
                        <li
                            key={link || "home"}
                            className={`m-3 ${activeClass}`}
                        >
                            <Link onClick={onClick} to={link}>
                                {label}
                            </Link>
                        </li>
                    );
                })}
                {/* <li
                    key={"lang"}
                    onClick={changeLanguage}
                    className={`m-3 cursor-pointer`}
                >
                    {nextLang.label}
                </li> */}
            </ul>
        </div>
    );
}
