import React, { useState, useCallback, useRef, useEffect } from "react";
import PhotoViewer from "../PhotoViewer";
import parseJson from "../../../../shared/utils/parseJson";

import "./style.scss";

let bodyElement;

export default function Waterfall({
    list,
    column,
    className,
    onNextPage = () => {},
    total = 0,
}) {
    // 当前查看的图片
    const wrapRef = useRef();
    const [curImg, setCurImg] = useState(null);
    const [curVideo, setCurVideo] = useState(null);
    const [curIndex, setCurIndex] = useState(-1);
    const [isShowPhotoView, setIsShowPhotoView] = useState(false);
    const posListRef = useRef([]);
    const listRef = useRef([]);

    const columnWrap = [];
    // 初始化列
    for (var i = 0; i < column; i++) {
        columnWrap[i] = { list: [], height: 0 };
    }

    const showPhotoView = useCallback((index) => {
        // 获取对应的DOM
        setCurIndex(index);
    }, []);

    const setCurrentVideoByIndex = useCallback(
        (index) => {
            const item = list[index];
            if (item) {
                let { id, videoKey } = item;
                setCurVideo(
                    videoKey
                        ? {
                              id,
                              sourceVideo:
                                  process.env.REACT_APP_OSS_URL +
                                  "/static/" +
                                  videoKey,
                              index,
                          }
                        : null,
                );
            }
        },
        [list, setCurVideo],
    );

    listRef.current = list;
    posListRef.current = [];
    // 把图片分配到瀑布流中
    list.forEach(
        (
            {
                id,
                pictureKey: big,
                videoKey: video,
                thumbnailUrl = "",
                ossBusiness = "",
            },
            index,
        ) => {
            const { width = "100%", height = "auto" } =
                parseJson(ossBusiness) || {};
            let curWrapIndex = findMinIndex(columnWrap);
            const curWrap = columnWrap[curWrapIndex];
            const ratio = height / width || 1;
            const sourceImg = process.env.REACT_APP_OSS_URL + "/static/" + big;
            const sourceVideo =
                process.env.REACT_APP_OSS_URL + "/static/" + video;

            const onItemClick = (e) => {
                showPhotoView(index);
                let _video = {
                    id,
                    sourceVideo,
                    index,
                };
                setCurVideo(video ? _video : null);
                e.stopPropagation();
                e.preventDefault();
            };
            // 计算图片在瀑布流中的顺序，用于点击时从瀑布流中查找
            posListRef.current.push([
                String(curWrapIndex),
                String(curWrap?.list.length),
            ]);
            curWrap.height += ratio;

            const originalPicture =
                process.env.REACT_APP_OSS_URL + "/static/" + big;
            // 这里不需要缩略图了，但为了保留发动痕迹，先放在 data-src 上了
            const smallPicture = `${process.env.REACT_APP_OSS_URL}${
                thumbnailUrl || "/static/" + big
            }`;
            curWrap.list.push(
                <>
                    <div
                        key={id}
                        className={`img-wrap box-border border-none cursor-pointer ${
                            isShowPhotoView ? "is-back" : ""
                        }`}
                        onClick={onItemClick}
                    >
                        <img
                            data-src={smallPicture}
                            width={width}
                            height={height}
                            alt=""
                            src={originalPicture}
                        />
                        {video ? (
                            <video
                                muted
                                loop
                                preload="true"
                                autoPlay
                                poster={sourceImg}
                            >
                                <source src={sourceVideo} type="video/mp4" />
                            </video>
                        ) : null}
                    </div>
                </>,
            );
        },
    );

    /** 翻页相关 */
    const nextHandle = useCallback(async () => {
        if (curIndex < total - 1) {
            let index = curIndex + 1;
            setCurIndex(index);
            setCurrentVideoByIndex(index);
        }
    }, [curIndex, total, setCurrentVideoByIndex]);

    const prevHandle = useCallback(() => {
        if (curIndex > 0) {
            let index = curIndex - 1;
            setCurIndex(index);
            setCurrentVideoByIndex(index);
        }
    }, [curIndex, setCurrentVideoByIndex]);

    const getImageByIndex = async (index) => {
        if (index < 0 || index >= total) {
            return null;
        }

        if (index >= list.length) {
            await onNextPage();
        }
        const column = posListRef.current[index][0];
        const row = posListRef.current[index][1];
        const image =
            wrapRef.current?.children?.[column]?.children[row]?.children[0];
        return image;
    };
    useEffect(() => {
        getImageByIndex(curIndex).then((image) => {
            setCurImg(image);
        });
        // eslint-disable-next-line
    }, [curIndex]);

    useEffect(() => {
        bodyElement = document.body;
        return () => {
            bodyElement = null;
        };
        // eslint-disable-next-line
    }, []);

    /** 翻页相关 */
    return (
        <div ref={wrapRef} className={`flex waterfall ${className}`}>
            <PhotoViewer
                video={curVideo}
                small={curImg?.src}
                big={curImg?.getAttribute("data-src")}
                isShow={!!curImg}
                image={curImg?.parentNode}
                onHideEnd={() => {
                    setCurIndex(-1);
                }}
                onHideStart={() => {
                    bodyElement?.classList.remove("overflow-hidden");
                    setIsShowPhotoView(false);
                }}
                onShowStart={() => {
                    bodyElement?.classList.add("overflow-hidden");
                    setIsShowPhotoView(true);
                }}
                onPrev={prevHandle}
                onNext={nextHandle}
                showNext={curIndex < total - 1}
                showPrev={curIndex > 0}
            />
            {columnWrap.map(({ list }, index) => {
                return (
                    <div key={index} className="flex-auto shrink grow w-full">
                        {list}
                    </div>
                );
            })}
        </div>
    );
}

// 找到数组中高度最小的
function findMinIndex(array) {
    var minIndex = 0;
    for (var i in array) {
        if (array[i].height < array[minIndex].height) {
            minIndex = i;
        }
    }
    return minIndex;
}
