import backgroundImg from "../../shared/assets/images/bg2.jpg";
import "./style.scss";

export default function About() {
    return (
        <div>
            <div className="about-page">
                <div className="pl-20 pr-20 about-text mt-10 pt-20 max-w-screen-lg ml-auto mr-auto">
                    <h2 className="text-2xl font-bold text-center">
                        We are TIPTOP, we pursue tiptop visual art.
                    </h2>

                    <p className="mt-10">
                        Tiptop is a Shanghai based high end architecture
                        visualization studio. We believe great visual art can
                        motivate people's fantastic inspiration of their life
                        and present them the future where they want to live.
                    </p>

                    <p className="mt-4">
                        At tiptop, we see our role as a keen observer of life,
                        discovering the subtle beauty in life. We are willing to
                        be a good listener, listening to the unique story of
                        each building. We strive to be an effective
                        communicator, connecting the complex reality with the
                        imagination of designers.
                    </p>

                    <p className="mt-4">
                        Our main products include still images, fly-through and
                        multimedia. To learn more, click on the link that’s most
                        suitable for you. Let's take you to an extraordinary
                        visual tour.
                    </p>
                </div>
                <div
                    style={{
                        background: "no-repeat center /cover",
                        backgroundImage: `url(${backgroundImg})`,
                        height: "auto",
                        aspectRatio: "16/9",
                    }}
                    className="bg ml-auto mr-auto w-full"
                    src={backgroundImg}
                    alt=""
                >
                    <canvas style={{width: "100%", height: "auto"}} width="1920" height="848"></canvas>
                </div>
            </div>
        </div>
    );
}
