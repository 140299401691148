import { createRef, useEffect } from "react";
import { throttle } from "lodash";
import "./style.scss";

export default function HomePoster({
    logo,
    image = "",
    slogan,
    name = "",
    styles = {
        slogan: {},
    },
}) {
    const sloganWrap = createRef();
    const onScroll = throttle((e) => {
        let st = document.getElementsByTagName("html")[0].scrollTop;
        if (st < window.innerHeight) {
            sloganWrap.current &&
                (sloganWrap.current.style.transform = `translateY(${
                    st / 20
                }px)`);
        }
    }, 100);
    useEffect(() => {
        document.addEventListener("scroll", onScroll);
        return () => {
            document.removeEventListener("scroll", onScroll);
        };
    }, [onScroll]);

    return (
        <div className="relative home-poster w-screen h-screen flex flex-col justify-center items-center">
            <div
                style={{
                    background: "no-repeat center /cover",
                    backgroundImage: `url(${image})`,
                }}
                className="absolute top-0 left-0 w-screen h-screen object-cover"
                alt=""
            ></div>
            <div
                ref={sloganWrap}
                className="slogan-wrap relative top-1 w-full text-center transition duration-100"
            >
                {logo && (
                    <img className="logo inline-block" src={logo} alt={name} />
                )}
                {slogan && (
                    <p
                        className="slogan mt-10 text-2xl font-bold"
                        style={styles.slogan}
                    >
                        {slogan}
                    </p>
                )}
            </div>
        </div>
    );
}
