import { useCallback, useEffect, useMemo, useState } from "react";
import { throttle, debounce } from "lodash";
import HomePoster from "./components/HomePoster";
import Waterfall from "./components/Waterfall";
import Copyright from "./components/Copyright";
import posterImg from "../../shared/assets/images/poster.jpg";
import ProductService from "../../services/productService";
import { getAppResource } from "../../apis/document";

import "./style.scss";
//Connect inspiration and create the ultimate future.
const Config = {
    poster: {
        image: posterImg,
        slogan: "",
    },
};

async function fetchHomeBg() {
    const doc = await getAppResource({
        docKey: "home_bg",
        source: "home",
        type: "image",
    });
    return doc ? process.env.REACT_APP_OSS_URL + doc : doc;
}

async function fetchLogo() {
    const doc = await getAppResource({
        docKey: "logo",
        source: "home",
        type: "image",
    });
    return doc ? process.env.REACT_APP_OSS_URL + doc : doc;
}

async function fetchSlogan() {
    const doc = await getAppResource({
        docKey: "slogan",
        source: "home",
        type: "text",
    });
    return doc;
}

export default function Home() {
    const productService = useMemo(() => new ProductService(), []);
    const [imageList, setImageList] = useState([]);
    const [page, setPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [total, setTotal] = useState(1);
    const [homeBg, setHomeBg] = useState();
    const [logo, setLogo] = useState();
    const [slogan, setSlogan] = useState("");
    const { poster } = Config;
    const getList = useCallback(async () => {
        const isEnd = imageList.length >= total;
        if (isLoading || isEnd) {
            return;
        }
        setIsLoading(true);
        setPage(page + 1);
        const res = await productService.getList(page);
        console.log(res);
        if (res?.list) {
            setImageList((imageList) => {
                const newList = [...imageList, ...res.list];
                setImageList(newList);
                setTotal(res.total);
            });
        }
        setIsLoading(false);
    }, [
        productService,
        page,
        setImageList,
        isLoading,
        total,
        imageList,
        setTotal,
    ]);

    const getHomeBg = async () => {
        const res = await fetchHomeBg();
        setHomeBg(res);
    };

    const getLogo = async () => {
        const res = await fetchLogo();
        setLogo(res);
    };

    const getSlogan = async () => {
        const res = (await fetchSlogan()) || poster.slogan;
        setSlogan(res);
    };

    const onScroll = debounce((e) => {
        const html = document.getElementsByTagName("html")[0];
        let st = html.scrollTop;
        if (st >= html.scrollHeight - html.clientHeight - 10) {
            getList();
        }
    }, 100);

    useEffect(() => {
        getList();
        getHomeBg();
        getLogo();
        getSlogan();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        document.addEventListener("scroll", onScroll, false);
        return () => {
            document.removeEventListener("scroll", onScroll);
        };
    }, [onScroll]);

    const [column, setColumn] = useState(2);
    const onResize = throttle(
        useCallback(() => {
            const ww = window.innerWidth;
            setColumn(ww < 750 ? 1 : 2);
        }, [setColumn]),
        200,
    );
    useEffect(() => {
        onResize();
        window.addEventListener("resize", onResize);
        return () => {
            window.removeEventListener("resize", onResize);
        };
    }, [onResize]);
    return (
        <div className="home-page">
            <HomePoster
                image={homeBg}
                slogan={slogan}
                logo={logo}
                styles={{
                    slogan: {
                        color: "#720000",
                    },
                }}
            />
            <Waterfall
                className="waterfall"
                list={imageList}
                column={column}
                total={total}
                onNextPage={getList}
            />
            <Copyright />
        </div>
    );
}
